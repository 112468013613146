import { AdjustmentType } from "@/components/batch/adjust/models/batch-adjust.model";
import { InventoryBatch, PBSummary } from "@/interfaces/batch";
import {
  BatchReconciliation,
  BatchReconciliationForm
} from "@/interfaces/traceability";
import { CallbackReturn } from "helix-vue-components/types";
import cloneDeep from "lodash/cloneDeep";
import round from "lodash/round";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./adjustModal.template.vue";

@Component({
  mixins: [Template]
})
export default class AdjustModalComponent extends Vue {
  @Prop({ required: true })
  public batch!: InventoryBatch;
  @Prop({ required: true })
  public traceDetails!: BatchReconciliation;
  @Prop({ required: true })
  public title!: string;
  @Prop({ required: true })
  public adjustmentTypes!: CallbackReturn<AdjustmentType[]>;

  @Prop({ required: true })
  public isLocal!: boolean;

  @Prop({ required: true })
  public value!: BatchReconciliationForm.Adjust | null;

  public filteredSummary: PBSummary[] = [];

  public model: BatchReconciliationForm.Adjust = {
    quantities: [],
    adjustment_reason: null,
    adjustment_type_id: null
  };

  public cancel() {
    this.$emit("reject");
  }

  public async adjust() {
    const valid = await this.$validator.validateAll();
    if (valid) {
      this.$emit("resolve", this.model);
    }
  }

  public get currentQuantity() {
    return this.isLocal
      ? +this.traceDetails.local.quantity
      : +(this.traceDetails.remote.quantity || 0);
  }

  public get newQuantity() {
    return this.model.quantities.reduce((sum, adjust) => {
      sum += +(adjust.quantity_value || 0);
      return sum;
    }, 0);
  }

  public get difference() {
    const diffNumber = Math.abs(
      round(this.newQuantity - +this.currentQuantity, 2)
    );

    const differencePercentage =
      this.newQuantity === 0
        ? 100
        : (this.newQuantity !== this.currentQuantity &&
            round((this.newQuantity / this.currentQuantity || 1) * 100, 2)) ||
          0;
    return `${diffNumber} (${differencePercentage}%)`;
  }

  protected created() {
    if (this.value) {
      if (this.isLocal) {
        this.model = cloneDeep(this.value);
        this.filteredSummary = this.batch.summary!.filter(
          room => room.batch_fraction_status_type === "AVAILABLE"
        );
      } else {
        this.model = {
          ...this.value,
          quantities: [
            {
              inventory_location_id: null,
              quantity_value: this.value.quantity_value!
            }
          ]
        };
      }
    } else {
      if (this.isLocal) {
        this.batch.summary!.forEach(room => {
          if (room.batch_fraction_status_type === "AVAILABLE") {
            this.filteredSummary.push(room);
            this.model.quantities.push({
              inventory_location_id: room.inventory_location_id,
              quantity_value: room.quantity_value
            });
          }
        });
      } else {
        this.model.quantities.push({
          inventory_location_id: null,
          quantity_value: this.traceDetails.remote.quantity
        });
      }
    }
  }
}
