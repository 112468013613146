import { AdjustmentType } from "@/components/batch/adjust/models/batch-adjust.model";
import ConfirmModalComponent from "@/components/sharedComponents/confirm/confirm.component";
import { ProductBatch, ProductVariant } from "@/interfaces/batch";
import {
  BatchReconciliation,
  BatchReconciliationForm,
  LocalData,
  MetrcData,
  MetrcItem,
  ReconciliationPayload
} from "@/interfaces/traceability";
import { batchService } from "@/services/batch.service";
import { traceabilityService } from "@/services/traceability.service";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import set from "lodash/set";
import { all, create } from "mathjs";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import AdjustModalComponent from "./adjustModal/adjustModal.component";
import Template from "./MetrcDetails.template.vue";

const mathJs = create(all, {});
@Component({
  mixins: [Template]
})
export default class MetrcDetailsComponent extends Vue {
  @Prop({ required: true })
  public batch!: ProductBatch;
  @Prop({ required: true })
  public variant!: ProductVariant;
  @Prop({ required: true })
  public onDisplay!: boolean;
  @Prop({ default: null })
  public results!: { response: any; success: boolean } | null;

  public loading = false;

  public traceDetails: BatchReconciliation | null = null;

  public reconciliationPayload: ReconciliationPayload | null = null;

  public tagStatuses: Array<{ text: string; value: string }> = [];
  public metrcWeighableOptions: Array<{ text: string; value: boolean }> = [];

  public localItemList: MetrcItem[] = [];
  public selectedLocalItem: MetrcItem | null = null;
  public remoteItemList: MetrcItem[] = [];
  public defaultItemList: MetrcItem[] = [];
  public loadingLocal = false;
  public canEditRemote = false;
  public loadingRemote = false;
  public remoteSearch: string = "";
  public localSearch: string = "";
  public adjustmentTypes: AdjustmentType[] = [];
  public editableDisplay = {
    localItem: false,
    remoteItem: false,
    tag: false,
    metrcWeighable: false,
    secondaryId: false
  };

  public fieldToPayload = {
    localItem: "local.item_mapped",
    remoteItem: "remote.metrc_item",
    tag: "remote.tag_status",
    metrcWeighable: "local.metrc_weighable",
    secondaryId: "local.tracking_id"
  };

  public auxModel: {
    localItem: string | null;
    localItemId: number | null;
    remoteItem: string | null;
    tag: string;
    metrcWeighable: boolean;
    secondaryId: string | null;
  } = {
    localItem: null,
    localItemId: null,
    remoteItem: "",
    tag: "",
    metrcWeighable: false,
    secondaryId: null
  };

  public searchItemsLocal = debounce(async (context: MetrcDetailsComponent) => {
    if (!context.searchItemsLocal && context.defaultItemList.length) {
      context.localItemList = context.defaultItemList;
      return;
    }
    context.loadingLocal = true;
    context.localItemList = await traceabilityService.findByMetrcName(
      context.localSearch
    );
    if (!context.searchItemsLocal) {
      context.defaultItemList = cloneDeep(context.localItemList);
    }
    context.loadingLocal = false;
  }, 500);

  public searchItemsRemote = debounce(
    async (context: MetrcDetailsComponent) => {
      if (!context.searchItemsRemote && context.defaultItemList.length) {
        context.remoteItemList = context.defaultItemList;
        return;
      }
      context.loadingRemote = true;
      context.remoteItemList = await traceabilityService.findByMetrcName(
        context.remoteSearch
      );

      if (!context.searchItemsRemote) {
        context.defaultItemList = cloneDeep(context.remoteItemList);
      }
      context.loadingRemote = false;
    },
    500
  );

  public get localModel(): Partial<LocalData> {
    return (
      (this.traceDetails &&
        !isEmpty(this.traceDetails.local) &&
        cloneDeep(this.traceDetails.local)) ||
      {}
    );
  }

  public get metrcModel(): Partial<MetrcData> {
    return (
      (this.traceDetails &&
        !isEmpty(this.traceDetails.remote) &&
        cloneDeep(this.traceDetails.remote)) ||
      {}
    );
  }

  public get status() {
    return (this.traceDetails && this.traceDetails.status) || [];
  }

  public get showWeighable(): boolean {
    return !!(
      this.variant &&
      !this.variant.requires_weighing &&
      this.variant.marijuana
    );
  }

  public get totalUsableWeight() {
    const total = mathJs.multiply!(
      mathJs.bignumber!(this.localModel.usable_weight || 0),
      mathJs.bignumber!(parseFloat(this.localQty))
    );
    return mathJs.format!(total, { precision: 4 });
  }

  public async openAdjust(isBiotrack: boolean) {
    if (!this.adjustmentTypes.length) {
      batchService
        .getAdjustmentTypes()
        .then(data => (this.adjustmentTypes = data));
    }
    this.$modals
      .load<BatchReconciliationForm.Adjust>(
        AdjustModalComponent,
        {
          size: "fit",
          positionX: "center",
          positionY: "top"
        },
        {
          batch: Object.assign(cloneDeep(this.batch), {
            tracking_id: this.auxModel.secondaryId || this.batch.tracking_id
          }),
          traceDetails: this.traceDetails,
          adjustmentTypes: () => this.adjustmentTypes,
          value: isBiotrack
            ? (this.reconciliationPayload!.local &&
                this.reconciliationPayload!.local.adjust) ||
              null
            : this.reconciliationPayload!.remote &&
              this.reconciliationPayload!.remote.adjust && {
                ...this.reconciliationPayload!.remote.adjust,
                adjustment_type_id: this.reconciliationPayload!.remote.adjust!
                  .batch_adjustment_type_id
              },
          isLocal: isBiotrack,
          title: this.$t(
            `metrc_reconciliation.${
              isBiotrack ? "biotrack" : "metrc"
            }_adjust_title`
          ).toString()
        }
      )
      .then(
        data => {
          if (isBiotrack) {
            this.reconciliationPayload!.local.adjust = data;
          } else {
            this.reconciliationPayload!.remote.adjust = {
              quantity_value: data.quantities.reduce((sum, adjustment) => {
                sum += +adjustment.quantity_value;
                return sum;
              }, 0),
              adjustment_reason: data.adjustment_reason!,
              batch_adjustment_type_id: data.adjustment_type_id!
            };
          }

          this.reconciliationPayload = cloneDeep(this.reconciliationPayload);
          this.reconciliationPayload!.tracking_id =
            this.auxModel.secondaryId || this.batch.tracking_id;
          this.emitChanges(true);
        },
        () => {
          // Nothing to do...
        }
      );
  }

  public toggleEdit(
    path: "localItem" | "remoteItem" | "tag" | "metrcWeighable" | "secondaryId"
  ) {
    this.editableDisplay[path] = !this.editableDisplay[path];
  }

  public saveChanges(
    field:
      | "localItem"
      | "remoteItem"
      | "tag"
      | "metrcWeighable"
      | "secondaryId",
    value: string | boolean | null
  ) {
    const path = this.fieldToPayload[field];
    set(this.reconciliationPayload!, path, value);
    if (field === "secondaryId") {
      this.reconciliationPayload!.tracking_id = String(value || "");
    }
    this.toggleEdit(field);
    this.emitChanges(true);
  }

  public changedLocalItem() {
    this.auxModel.localItem =
      (this.selectedLocalItem && this.selectedLocalItem.name) || null;
    this.auxModel.localItemId =
      (this.selectedLocalItem && this.selectedLocalItem.id) || null;
    this.searchItemsLocal.cancel();
  }
  @Watch("localSearch")
  public searchLocal() {
    this.searchItemsLocal(this);
  }
  @Watch("remoteSearch")
  public searchRemote() {
    this.searchItemsRemote(this);
  }

  @Watch("results")
  public async processResults() {
    if (this.results && this.results.response) {
      await this.init();
      if (this.results.success) {
        // Validates the reconciliation status before navigating...
        if (
          this.traceDetails &&
          (this.traceDetails.status.length > 1 ||
            this.traceDetails.status[0] !== "IN_SYNC")
        ) {
          this.$modals
            .load(
              ConfirmModalComponent,
              {
                size: "small"
              },
              {
                modalData: {
                  title: "batch_convert.title_warning",
                  body: "metrc_reconciliation.reco_ok_warn",
                  icon: "fal fa-exclamation-triangle"
                }
              }
            )
            .then(
              () => {
                this.$router.back();
              },
              () => {
                /** Nothing to do */
              }
            );
        } else {
          traceabilityService.showReconcileSuccess();
          this.$router.back();
        }
      }
    }
  }

  public emitChanges(hasChanged: boolean) {
    this.$emit("changed", {
      payload: this.reconciliationPayload,
      hasChanged
    });
  }

  public get localQty() {
    if (
      this.reconciliationPayload &&
      this.reconciliationPayload.local &&
      this.reconciliationPayload.local.adjust
    ) {
      const sum = this.reconciliationPayload.local.adjust.quantities.reduce(
        (acc, adjustment) => {
          acc += +adjustment.quantity_value;
          return acc;
        },
        0
      );

      return `${sum} ${this.localModel.unit}`;
    }

    return `${this.localModel.quantity} ${this.localModel.unit}`;
  }

  public get remoteQty() {
    if (
      this.reconciliationPayload &&
      this.reconciliationPayload.remote &&
      this.reconciliationPayload.remote.adjust
    ) {
      return `${this.reconciliationPayload.remote.adjust.quantity_value} ${
        this.metrcModel.unit
      }`;
    }
    return `${this.metrcModel.quantity} ${this.metrcModel.unit || ""}`;
  }

  public get metrcQuantityConversion() {
    return (
      (this.metrcModel.quantity_conversion &&
        `${this.metrcModel.quantity_conversion} ${this.$t(
          "metrc_reconciliation.grams"
        ).toString()}`) ||
      ""
    );
  }

  public mounted() {
    this.tagStatuses = [
      {
        text: this.$t("metrc_reconciliation.finished").toString(),
        value: "FINISHED"
      },
      {
        text: this.$t("metrc_reconciliation.unfinished").toString(),
        value: "UNFINISHED"
      }
    ];

    this.metrcWeighableOptions = [
      {
        text: this.$t("yes").toString(),
        value: true
      },
      {
        text: this.$t("no").toString(),
        value: false
      }
    ];
    const unwatch = this.$watch("onDisplay", () => {
      if (this.onDisplay) {
        this.init();
        unwatch();
      }
    });
  }

  protected async init() {
    this.loading = true;
    this.traceDetails = await traceabilityService.getBatchDetails(
      this.batch.batch_uid
    );
    this.canEditRemote = !this.traceDetails!.status.includes(
      "MISSING_TRACKING_ID"
    );
    await this.setupPayload();
    this.loading = false;
  }

  protected async setupPayload() {
    this.reconciliationPayload = {
      batch_uid: this.batch.batch_uid,
      tracking_id:
        this.batch.tracking_id ||
        (this.traceDetails && this.traceDetails.local.tracking_id) ||
        "",
      local: {},
      remote: {}
    };

    [this.localItemList, this.remoteItemList] = await Promise.all([
      traceabilityService.findByMetrcName(
        this.traceDetails!.local.metrc_item_mapped
      ),
      traceabilityService.findByMetrcName(this.traceDetails!.remote.metrc_item)
    ]);

    this.auxModel = {
      tag: this.traceDetails!.remote.tag_status,
      remoteItem: this.traceDetails!.remote.metrc_item,
      metrcWeighable: !!this.traceDetails!.local.metrc_weighable,
      localItemId: null,
      localItem:
        (this.traceDetails && this.traceDetails.local.metrc_item_mapped) ||
        null,
      secondaryId: this.traceDetails!.local.tracking_id
    };

    this.emitChanges(false);
  }
}
